import mrstylishstore from '../images/cases/mrstylish.png';
import rodticket from '../images/cases/rodticket2.png';
import rodsuite from '../images/cases/rodsuite2.png';

const CasesSucesso = [
    {
        Id: '1',
        sImg: mrstylishstore,
        title: 'Mr.Stylish Store - Moda Masculina e Feminina',
        slug: 'mr-stylish-store',
        thumb1: 'Moda',
        thumb2: 'Estilo',
        url: 'https://mrstylishstore.com',
        col: 'col-lg-6',
        description: 'Mr.Stylish Store é uma referência no e-commerce de moda no Brasil, oferecendo as últimas tendências em roupas e acessórios para o público masculino e feminino. A loja se destaca pela sua vasta seleção de produtos de alta qualidade e um serviço de entrega rápida e segura em Maringá e região. Confira mais em https://www.mrstylishstore.com.',
    },
    {
        Id: '2',
        sImg: rodticket,
        title: 'RodTicket - Atendimento ao Cliente',
        slug: 'rodticket',
        thumb1: 'Atendimento',
        thumb2: 'Cliente',
        url: 'https://rodticket.com',
        col: 'col-lg-6',
        description: 'A RODTICKET centraliza o gerenciamento de atendimentos via WhatsApp, Facebook e Instagram, ajudando sua empresa a otimizar o suporte ao cliente em todos os canais de comunicação. ',
    },
    {
        Id: '3',
        sImg: rodsuite,
        title: 'RodSuite - Sistema Completo para Gerenciamento de Leads, Projetos e RH',
        slug: 'rodsuite',
        thumb1: 'Gerenciamento',
        thumb2: 'Leads, Projetos e RH',
        url: 'https://rodsuite.com',
        col: 'col-lg-6',
        description: 'RodSuite é uma plataforma completa para gestão de leads, clientes, projetos e RH. Perfeito para empresas que buscam otimizar a operação e crescer com eficiência. Experimente grátis por 14 dias.',
    },
];

export default CasesSucesso;
